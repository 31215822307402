const MAPS_API_KEY = "AIzaSyCuQmxqgWcP4flO66XKWNICyUNHVA0cAbg";
let OPVS_URL = "";
let BASE_URL = "";
const domainUrl = window.location.host;


console.log("domainUrl:",domainUrl)

switch (domainUrl) {
  case "cda.graana.rocks":
    OPVS_URL = "https://opvs.cda.gov.pk";
    BASE_URL = "https://cdaadmin.graana.rocks";
    break;
  case "uat.cda.gov.pk":
    OPVS_URL = "https://uat-cdaopvs.graana.rocks/";
    BASE_URL = "https://uat-cdaadmin.graana.rocks";
    break;
  case "cda.gov.pk":
    OPVS_URL = "https://opvs.cda.gov.pk/";
    BASE_URL = "https://admin.cda.gov.pk";
    break;
    case "125.209.103.36":
      OPVS_URL = "https://opvs.cda.gov.pk/";
      BASE_URL = "https://admin.cda.gov.pk";
      break;
    case "uat-cdaweb.graana.rocks":
      OPVS_URL = "https://uat-cdaopvs.graana.rocks/map";
       BASE_URL = "https://uat-cdaadmin.graana.rocks";
        break;
  default:

    // live or production
    //  OPVS_URL = "https://opvs.cda.gov.pk/";
    //  BASE_URL = "https://admin.cda.gov.pk";
   
    // uat
    OPVS_URL = "https://uat-cdaopvs.graana.rocks/map";
    BASE_URL = "https://uat-cdaadmin.graana.rocks";
    break;
}

export { BASE_URL, OPVS_URL, MAPS_API_KEY };
